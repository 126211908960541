// TODO: split the file
import get from 'lodash/get';
import { createSelector } from 'reselect';

import {
  AppFeatureType,
  AppName,
  AppSegmentType,
  BodyStyle,
  DamageReportImageType,
} from 'src/api/globalTypes';
import {
  CAR_CODE_STORAGE_KEY,
  DAMAGE_CODE_STORAGE_KEY,
  FLOW_STORAGE_KEY,
  IS_CODE_BASE_AUTH,
  MANAGER_CODE_KEY,
  REPAIRFIX_HOME_URL,
  SESSION_ID,
  WebAppFlows,
} from 'src/apps/NewDriverApp/constants';
import { GetDamageReportInfoNew_damageReportInfo_additionalServices as additionalServiceType } from 'src/apps/NewDriverApp/graphql/queries/__generated__/GetDamageReportInfoNew';
import { CarIdentificationReducer } from 'src/apps/NewDriverApp/redux/reducers/car-identification-reducer';
import { DamageReasonsReducer } from 'src/apps/NewDriverApp/redux/reducers/damage-reasons-reducer';
import { RootState } from 'src/apps/NewDriverApp/redux/root-reducer';
import { AppMode, InitState as AppState } from 'src/apps/NewDriverApp/redux/types/AppState';
import {
  ImageState,
  InfoCar,
  InfoDocument,
  InfoReducer,
} from 'src/apps/NewDriverApp/redux/types/Types';
import { ThemeOverrides } from 'src/apps/NewDriverApp/theme/ThemeInterface';

import {
  AccidentAdditionalQuestionsConfig,
  AccidentAdditionalQuestionsPageSupportedModules,
} from '../../flows/fleet/accident/AccidentAdditionalQuestionsPage/types';
import { CHECKLIST_CODE_STORAGE_KEY } from '../../flows/fleet/checklist/constants';
import { DriverFacingAppScreens } from '../../types';
import { findDamageReportImageByType } from '../../utils/helpers';
import { additionalServicesReducerType } from '../reducers/additional-services-reducer';
import { BodyTypeReducerState } from '../reducers/body-types-reducer';
import { DamageTypeReducer } from '../reducers/damage-type-reducer';
import { ReportReducer } from '../reducers/report-reducer';
import { SavingReportReducer } from '../reducers/save-report-reducer';

export const reportSelector = (state: RootState): ReportReducer => state.report;
export const reportImagesSelector = (state: RootState): ImageState[] => state.report.images;
export const carIdentificationSelector = (state: RootState): CarIdentificationReducer =>
  state.carIdentification;
export const carBodyTypeSelector = (state: RootState): BodyStyle | null =>
  state.carIdentification.carInfo.bodyStyle;

export const reportCarSelector = (state: RootState): InfoCar | undefined => state.report.info.car;

export const reportInfo = (state: RootState): InfoReducer => state.report.info;

export const reportDocumentsSelector = (state: RootState): InfoDocument[] =>
  state.report.info.documents || [];

export const registrationImageSelector = (state: RootState): ImageState | undefined =>
  state.report.images.find(
    findDamageReportImageByType(DamageReportImageType.REGISTRATION_DOCUMENT),
  );

export const cockpitImageSelector = (state: RootState): ImageState | undefined =>
  state.report.images.find(findDamageReportImageByType(DamageReportImageType.DASHBOARD));

export const appConfigurationSelector = (state: RootState): AppState => state.app;

export const appScreensConfiguration = createSelector(
  appConfigurationSelector,
  ({ configuration }) => {
    const { screens } = configuration || {};
    return (screens || [])[0];
  },
);

export const getCountryCode = createSelector(appConfigurationSelector, ({ configuration }) => {
  return configuration?.countryCode;
});

export const appFeaturesConfiguration = createSelector(
  appConfigurationSelector,
  ({ configuration }) => {
    const { features } = configuration || {};

    return features;
  },
);

export const anylineTokenSelector = createSelector(
  appFeaturesConfiguration,
  features =>
    features?.find(feature => feature.name === AppFeatureType.CAR_IDENTIFICATION)?.options
      .anylineToken,
);

export const appLocalesConfiguration = createSelector(
  appConfigurationSelector,
  ({ configuration }) => {
    const { locales } = configuration || {};

    return locales || [];
  },
);

export const accidentAdditionalQuestionsConfig = (
  moduleName: AccidentAdditionalQuestionsPageSupportedModules,
) =>
  createSelector(appConfigurationSelector, ({ configuration }) => {
    const { features } = configuration || {};

    const config = features?.find(({ name }) => name === moduleName);

    const questions = get(config, 'options.questions', {}) as AccidentAdditionalQuestionsConfig;

    return questions;
  });

export const isAdditionalQuestionsEnabled = (
  moduleName: AccidentAdditionalQuestionsPageSupportedModules,
) =>
  createSelector(accidentAdditionalQuestionsConfig(moduleName), questions => {
    return Object.values(questions).some(Boolean);
  });

export const appReportedByPrefill = createSelector(appFeaturesConfiguration, features => {
  const reportedBy = features
    ?.find(i => i.name === AppFeatureType.DRIVER_INFORMATION)
    ?.options?.fields?.map((i: any) => i?.name)
    .includes('reportedBy');

  return Boolean(reportedBy);
});

export const appScreenConfiguration = (screen?: DriverFacingAppScreens) =>
  createSelector(appScreensConfiguration, appScreensCfg => {
    const shouldRetrieveConfig = !!appScreensCfg && !!screen;
    return shouldRetrieveConfig ? appScreensCfg.screensConfiguration[screen] : undefined;
  });

export const appSegmentSelector = (state: RootState): AppSegmentType => {
  try {
    return state.app.configuration!.segment;
  } catch (e) {
    throw e;
  }
};

export const appInitParams = createSelector(
  appConfigurationSelector,
  ({ initParams }) => initParams || {},
);

export const saveReportSelector = (state: RootState): SavingReportReducer => state.saveReportState;

export const reportBodyTypeSelector = (state: RootState): BodyTypeReducerState =>
  state.report.bodyTypes;

export const getCarPartsSelector = createSelector(
  reportSelector,
  (report: ReportReducer) => report.carParts,
);

export const getDamageReportHistorySelector = createSelector(
  reportSelector,
  (report: ReportReducer) => report.damageReportHistory,
);

export const getAppointmentDateSelector = createSelector(
  reportSelector,
  (report: ReportReducer) => report.scheduleAppointment,
);

export const getDamageTypesSelector = (state: RootState): DamageTypeReducer =>
  state.report.damageTypes;

export const damageReasonsSelector = createSelector(reportSelector, report => report.damageReasons);

export const selectedDamageReason = createSelector(
  damageReasonsSelector,
  (damageReason: DamageReasonsReducer) => damageReason.reason,
);

export const selectedDescription = createSelector(
  damageReasonsSelector,
  (damageReason: DamageReasonsReducer) => damageReason.description,
);

export const getCustomerWebsiteUrl = ({ app }: RootState) => {
  if (app && app.configuration && app.configuration.webSiteUrl) {
    return app.configuration.webSiteUrl;
  }
  return REPAIRFIX_HOME_URL;
};

export const additionalServicesSelector = createSelector(
  reportSelector,
  report => report.additionalServices,
);

export const freeAdditionalServicesSelector = createSelector(
  additionalServicesSelector,
  (additionalServices: additionalServicesReducerType) =>
    additionalServices.filter((service: additionalServiceType) => !service.price),
);

export const chargeableAdditionalServicesSelector = createSelector(
  additionalServicesSelector,
  (additionalServices: additionalServicesReducerType) =>
    additionalServices.filter((service: additionalServiceType) => !!service.price),
);

export const allAdditionalServicesSelector = createSelector(
  additionalServicesSelector,
  (additionalServices: additionalServicesReducerType) => additionalServices,
);

export const registrationDataSelector = createSelector(reportSelector, report => report.ocr);

export const additionalInformationSelector = createSelector(
  reportSelector,
  report => report.additionalInformation,
);

// Driver information
export const driverInformationSelector = createSelector(
  reportSelector,
  report => report.driverInformation,
);

// Insurance information
export const insuranceInformationSelector = createSelector(
  reportSelector,
  report => report.insuranceInformation,
);

// Date and Location
export const dateAndLocationSelector = createSelector(
  reportSelector,
  report => report.dateAndLocation,
);

// Other accident party
export const otherAccidentPartySelector = createSelector(
  reportSelector,
  report => report.otherAccidentParty,
);

export const bodyshopInfo = createSelector(reportSelector, (report: ReportReducer) => {
  return report.info.bodyshop;
});

export const carSelector = createSelector(reportSelector, (report: ReportReducer) => {
  return report.info.car;
});

export const savedRegistrationPhoto = createSelector(
  saveReportSelector,
  saveReport => saveReport.savedRegistrationPhoto,
);

export const isPreInspectionOrTrivialReportsCompleting = createSelector(
  saveReportSelector,
  saveReport => saveReport.isCompleting,
);

export const legalAgreementsSelector = createSelector(
  reportSelector,
  report => report.agreements.entity,
);

export const driverCameraAccessConsentData = createSelector(
  reportSelector,
  report => report.agreements.driverCameraAccessConsent,
);

export const isCarIdentificationSavingState = ({ carIdentification }: RootState) => ({
  saving: carIdentification.saving,
  savingError: carIdentification.savingError,
});

export const getAssetsData = (state: RootState) => state.assets;

export const getIsContentLoadingFailedAssetsData = createSelector(
  getAssetsData,
  ({ isContentLoadingFailed }) => isContentLoadingFailed,
);

export const getIsContentLoadingAssetsData = createSelector(
  getAssetsData,
  ({ isContentLoading }) => isContentLoading,
);

export const isInEmbeddedMode = (state: RootState) =>
  state.app.initParams?.mode === AppMode.EMBEDDED;

export const getCustomerTheme = (state: RootState): ThemeOverrides | null => state.app.theme || {};

export const getChecklistCode = (state: RootState): string | null =>
  get(state, `auth.tokens.${CHECKLIST_CODE_STORAGE_KEY}`, null);

export const getFlowKey = (state: RootState): WebAppFlows | null =>
  get(state, `auth.${FLOW_STORAGE_KEY}`);

export const getCarKey = (state: RootState): string | null =>
  get(state, `auth.tokens.${CAR_CODE_STORAGE_KEY}`, null);

export const getDamageKey = (state: RootState): string | null =>
  get(state, `auth.tokens.${DAMAGE_CODE_STORAGE_KEY}`, null);

export const getRequestSource = (state: RootState): string =>
  get(state, 'app.configuration.requestSource');

export const getAppSessionID = (state: RootState): string => get(state, `app.${SESSION_ID}`);

export const getHasAuth = (state: RootState): string | null => state.auth[IS_CODE_BASE_AUTH];

export const getManagerCode = (state: RootState): string | null =>
  get(state, `auth.tokens.${MANAGER_CODE_KEY}`, null);

export const getIsManagerApp = (state: RootState) => state.auth.appName === AppName.MANAGER_APP;

export const getIsLoggingOut = (state: RootState): boolean => state.auth.isLoggingOut;

export const getNotificationData = (state: RootState) => state.notification;

export const getNavigationData = (state: RootState) => state.navigation;

export const getCurrentChecklistStep = createSelector(getNavigationData, navigation =>
  get(navigation, 'currentLocation.state.configuration', null),
);

export const getExtendedPermissions = (state: RootState) => state.auth.extendedPermissions;

export const getIsWebAppFlowAllowed = createSelector(getFlowKey, flow => {
  return (checkFlow: WebAppFlows | WebAppFlows[]) => {
    const flows = Array.isArray(checkFlow) ? checkFlow : [checkFlow];

    return !flow || flows.includes(flow);
  };
});

export const getIsWebAppFlow = createSelector(getFlowKey, flow => {
  return (checkFlow: WebAppFlows) => {
    return flow === checkFlow;
  };
});

export const getFeatureConfiguration = (featureName: AppFeatureType) =>
  createSelector(appFeaturesConfiguration, features => {
    return features?.find(i => i.name === featureName) || null;
  });

export const getWizardData = (wizardName: string | undefined) =>
  createSelector(
    (state: RootState) => state.wizards,
    wizards => {
      return wizardName ? wizards[wizardName] : undefined;
    },
  );

export const isOCREnabledSelector = createSelector(appFeaturesConfiguration, features => {
  return (features?.find(feature => feature.name === AppFeatureType.CAR_IDENTIFICATION) || null)
    ?.options?.isOCREnabled;
});

export const isDriverInformConsentEnabledSelector = (featureName: AppFeatureType) =>
  createSelector(getFeatureConfiguration(featureName), feature => {
    return feature?.options?.isDriverInformConsentEnabled;
  });
